import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Stack } from '@chakra-ui/react';
import join from 'lodash/join';

import { useGetVendedoraAlugueis } from 'actions/vendedoras';
import {
  DisplayText,
  Paginator,
  Table,
  TableHead,
  TableBodyZebra,
  TableRow,
  TableHeader,
  TableData,
  StatusAluguel
} from 'components/ui';

import { formatDate } from 'utils/ui';

const TableRowAluguel = ({ value }) => {
  const history = useNavigate();
  const { id, data_retirada, data_devolucao, itens, status } = value;

  return (
    <TableRow key={id} onClick={() => history(`/aluguel/${id}`)}>
      <TableData>{data_retirada ? formatDate(data_retirada) : ''}</TableData>
      <TableData>{data_devolucao ? formatDate(data_devolucao) : ''}</TableData>
      <TableData>
        {join(
          itens.map(i => i.referencia),
          ', '
        )}
      </TableData>
      <TableData>
        <StatusAluguel value={status} />
      </TableData>
    </TableRow>
  );
};

const TabContentAlugueis = ({ id }) => {
  const [page, setPage] = React.useState(1);

  const { data: alugueis, status, hasNext, isFetching } = useGetVendedoraAlugueis({
    id,
    page
  });

  if (status === 'loading') return <DisplayText>Carregando...</DisplayText>;

  return (
    <Stack>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeader>Data Retirada</TableHeader>
            <TableHeader>Data Devolução</TableHeader>
            <TableHeader>Itens</TableHeader>
            <TableHeader>Status</TableHeader>
          </TableRow>
        </TableHead>
        <TableBodyZebra>
          {alugueis.data.results.length === 0 ? (
            <TableRow>
              <TableData colSpan="4">Nenhuma informação disponível</TableData>
            </TableRow>
          ) : (
            alugueis.data.results.map(aluguel => (
              <TableRowAluguel key={aluguel.id} value={aluguel} />
            ))
          )}
        </TableBodyZebra>
      </Table>
      <Paginator page={page} onPaginate={setPage} hasNext={hasNext} isFetching={isFetching}/>
    </Stack>
  );
};

export default TabContentAlugueis;
