import React from 'react';
import {
  Link as RouterLink,
  useNavigate
} from 'react-router-dom';
import {
  Grid,
  Stack,
  Text,
  Flex,
  Image,
  Input,
  IconButton
} from '@chakra-ui/react';
import get from 'lodash/get';
import { FaSearch, FaPlus } from 'react-icons/fa';
import { stringify } from 'qs';

import { useGetClientes } from 'actions/clientes';
import { Paginator } from 'components/ui';
import { useSearchParams, useDocumentTitle } from 'customHooks';

const initialFiltros = {
  page: 1
};

const DisplayText = ({ children }) => {
  return (
    <Text color="gray.400" as="em">
      {children}
    </Text>
  );
};

const Card = ({ value }) => {
  return (
    <Flex
      as={RouterLink}
      to={`/clientes/${value.id}`}
      min-width="200px"
      border="1px"
      borderColor="#DDD"
      borderRadius="md"
      boxShadow="sm"
      align="center"
      p={1}
    >
      <Image
        src={value.foto_url}
        alt={value.nome}
        boxSize="100px"
        fallbackSrc="/static/img/pessoa/default.jpg"
      />
      <Stack spacing={1} px={4}>
        <Text color="blue.500" fontWeight="semibold" fontSize="sm">
          {value.nome}
        </Text>
        <Text color="gray.400" fontSize="sm">
          Cliente
        </Text>
      </Stack>
    </Flex>
  );
};

const GridClientes = ({ filtros, onPaginate }) => {
  const { page } = filtros;
  const { status, data: clientes, error, hasNext, isFetching } = useGetClientes(
    filtros
  );

  if (status === 'loading') return <DisplayText>Carregando...</DisplayText>;

  if (status === 'error')
    return <DisplayText>{get(error, 'response.data.detail')}</DisplayText>;

  const { results } = clientes.data;

  if (results.length === 0)
    return <DisplayText>Nenhuma informação disponível</DisplayText>;

  return (
    <Stack spacing={4}>
      <Grid
        templateColumns={[
          'repeat(1, 1fr)',
          'repeat(2, 1fr)',
          'repeat(3, 1fr)',
          'repeat(3, 1fr)'
        ]}
        gap={4}
      >
        {results.map(cliente => (
          <Card key={cliente.id} value={cliente} />
        ))}
      </Grid>
      <Paginator page={page} onPaginate={onPaginate} isFetching={isFetching} hasNext={hasNext} />
    </Stack>
  );
};

const Lista = () => {
  const searchParams = useSearchParams(initialFiltros);
  const [filtros, setFiltrosState] = React.useState(searchParams);
  const [termoPesquisa, setTermoPesquisa] = React.useState('');
  const history = useNavigate();
  useDocumentTitle('Clientes');

  const setFiltros = React.useCallback(
    newFiltros => {
      const filtros = { ...searchParams, ...newFiltros };
      history(`?${stringify(filtros)}`, { replace: true });
    },
    [setFiltrosState, searchParams]
  );

  React.useEffect(() => {
    setTermoPesquisa(filtros.search || '');
  }, [filtros]);

  React.useEffect(() => {
    setFiltrosState(searchParams);
  }, [searchParams]);

  return (
    <Stack spacing={4} p={4}>
      <Flex>
        <Flex
          as="form"
          ml="auto"
          noValidate
          onSubmit={e => {
            e.preventDefault();
            //setFiltros(prev => ({ ...prev, search: termoPesquisa }));
            setFiltros({search: termoPesquisa, page:1});
          }}
        >
          <Input
            size="sm"
            placeholder="Pesquisar"
            roundedRight={0}
            value={termoPesquisa}
            onChange={e => setTermoPesquisa(e.target.value)}
          />
          <IconButton type="submit" size="sm" rounded={0} icon={<FaSearch/>} />
          <IconButton
            as={RouterLink}
            to={`form`}
            size="sm"
            roundedLeft={0}
            icon={<FaPlus/>}
          />
        </Flex>
      </Flex>
      <GridClientes
        filtros={filtros}
        //onPaginate={page => setFiltros(prev => ({ ...prev, page }))}
        onPaginate={page => setFiltros({page })}
      />
    </Stack>
  );
};

export default Lista;
