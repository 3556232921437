import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Stack, Heading } from '@chakra-ui/react';

import {
  useGetAlugueisRetiradas,
  useGetAlugueisDevolucoes
} from 'actions/alugueis';
import {
  useGetLavanderiasIndo,
  useGetLavanderiasVoltando
} from 'actions/lavanderias';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableHeader,
  TableHeaderIcon,
  TableData,
  DisplayText,
  DisplayErrors,
  StatusAluguel,
  Paginator,
  StatusLavanderia,
  ControlNull
} from 'components/ui';
import { ImArrowUp, ImArrowDown } from "react-icons/im";
import { formatDate } from 'utils/ui';
import join from 'lodash/join';
import map from 'lodash/map';

const TableRetiradasAluguel = props => {
  const [filtros, setFiltros] = React.useState({ page: 1 });
  const { data, status, error, hasNext, isFetching } = useGetAlugueisRetiradas(filtros);
  const history = useNavigate();

  if (status === 'loading') return <DisplayText>Carregando...</DisplayText>;
  if (status === 'error') return <DisplayErrors errors={error.errors} />;

  const { results } = data.data;
  const { page } = filtros;

  return (
    <Stack {...props}>
      <Heading size="md">Retiradas Aluguel</Heading>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeader w={"10%"}>#</TableHeader>
            <TableHeader w={"20%"}>Data de Retirada</TableHeader>
            <TableHeader w={"30%"}>Cliente</TableHeader>
            <TableHeader w={"25%"}>
              Itens <TableHeaderIcon icon={ImArrowUp} />
            </TableHeader>
            <TableHeader w={"15%"}>Status</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {results.length === 0 && (
            <TableRow>
              <TableData colSpan="5">Nenhuma informação disponível</TableData>
            </TableRow>
          )}
          {results.map(item => (
            <TableRow
              key={item.id}
              onClick={() => history(`/aluguel/${item.id}`)}
              bg={item.is_atrasado ? 'yellow.50' : ''}
            >
              <TableData>{item.id}</TableData>
              <TableData>{formatDate(item.data_retirada)}</TableData>
              <TableData>{item.cliente_nome}</TableData>
              <TableData>{join(map(item.itens, 'referencia'), ', ')}</TableData>
              <TableData>
                <StatusAluguel value={item.status} />
              </TableData>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Paginator
        page={page}
        onPaginate={page => setFiltros(prev => ({ ...prev, page }))}
        hasNext={hasNext}
        isFetching={isFetching}
      />
    </Stack>
  );
};

const TableIndoParaLavanderia = props => {
  const [filtros, setFiltros] = React.useState({ page: 1 });
  const { data, status, error, hasNext, isFetching } = useGetLavanderiasIndo(filtros);
  const history = useNavigate();

  if (status === 'loading') return <DisplayText>Carregando...</DisplayText>;
  if (status === 'error') return <DisplayErrors errors={error.errors} />;

  const { results } = data.data;
  const { page } = filtros;

  return (
    <Stack {...props}>
      <Heading size="md">Indo para Lavanderia</Heading>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeader w={20}>#</TableHeader>
            <TableHeader w={44}>Data de Ida</TableHeader>
            <TableHeader>Empresa</TableHeader>
            <TableHeader>
              Item <TableHeaderIcon icon={ImArrowUp} />
            </TableHeader>
            <TableHeader w={40}>Status</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {results.length === 0 && (
            <TableRow>
              <TableData colSpan="5">Nenhuma informação disponível</TableData>
            </TableRow>
          )}
          {results.map(item => (
            <TableRow
              key={item.id}
              onClick={() => history(`/lavanderia/${item.id}`)}
              bg={item.is_atrasado ? 'yellow.50' : ''}
            >
              <TableData>{item.id}</TableData>
              <TableData>{formatDate(item.data_inicio)}</TableData>
              <TableData>{item.empresa?item.empresa_nome:<ControlNull/>}</TableData>
              <TableData>{item.item_referencia}</TableData>
              <TableData>
                <StatusLavanderia value={item.status} />
              </TableData>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Paginator
        page={page}
        onPaginate={page => setFiltros(prev => ({ ...prev, page }))}
        hasNext={hasNext}
        isFetching={isFetching}
      />
    </Stack>
  );
};

const TableDevolucoesAluguel = props => {
  const [filtros, setFiltros] = React.useState({ page: 1 });
  const { data, status, error, hasNext, isFetching } = useGetAlugueisDevolucoes(filtros);
  const history = useNavigate();

  if (status === 'loading') return <DisplayText>Carregando...</DisplayText>;
  if (status === 'error') return <DisplayErrors errors={error.errors} />;

  const { results } = data.data;
  const { page } = filtros;

  return (
    <Stack {...props}>
      <Heading size="md">Devoluções Aluguel</Heading>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeader w={20}>#</TableHeader>
            <TableHeader w={44}>Data de Devolução</TableHeader>
            <TableHeader>Cliente</TableHeader>
            <TableHeader>
              Itens <TableHeaderIcon icon={ImArrowDown} />
            </TableHeader>
            <TableHeader w={40}>Status</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {results.length === 0 && (
            <TableRow>
              <TableData colSpan="5">Nenhuma informação disponível</TableData>
            </TableRow>
          )}
          {results.map(item => (
            <TableRow
              key={item.id}
              onClick={() => history(`/aluguel/${item.id}`)}
              bg={item.is_atrasado ? 'yellow.50' : ''}
            >
              <TableData>{item.id}</TableData>
              <TableData>{formatDate(item.data_devolucao)}</TableData>
              <TableData>{item.cliente_nome}</TableData>
              <TableData>{join(map(item.itens, 'referencia'), ', ')}</TableData>
              <TableData>
                <StatusAluguel value={item.status} />
              </TableData>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Paginator
        page={page}
        onPaginate={page => setFiltros(prev => ({ ...prev, page }))}
        hasNext={hasNext}
        isFetching={isFetching}
      />
    </Stack>
  );
};

const TableVoltandoDaLavanderia = props => {
  const [filtros, setFiltros] = React.useState({ page: 1 });
  const { data, status, error, hasNext, isFetching } = useGetLavanderiasVoltando(filtros);
  const history = useNavigate();

  if (status === 'loading') return <DisplayText>Carregando...</DisplayText>;
  if (status === 'error') return <DisplayErrors errors={error.errors} />;

  const { results } = data.data;
  const { page } = filtros;

  return (
    <Stack {...props}>
      <Heading size="md">Voltando para Lavanderia</Heading>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeader w={20}>#</TableHeader>
            <TableHeader w={44}>Data de Volta</TableHeader>
            <TableHeader>Empresa</TableHeader>
            <TableHeader>
              Item <TableHeaderIcon icon={ImArrowDown} />
            </TableHeader>
            <TableHeader w={40}>Status</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {results.length === 0 && (
            <TableRow>
              <TableData colSpan="5">Nenhuma informação disponível</TableData>
            </TableRow>
          )}
          {results.map(item => (
            <TableRow
              key={item.id}
              onClick={() => history(`/lavanderia/${item.id}`)}
              bg={item.is_atrasado ? 'yellow.50' : ''}
            >
              <TableData>{item.id}</TableData>
              <TableData>{formatDate(item.data_inicio)}</TableData>
              <TableData>{item.empresa?item.empresa_nome:<ControlNull/>}</TableData>
              <TableData>{item.item_referencia}</TableData>
              <TableData>
                <StatusLavanderia value={item.status} />
              </TableData>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Paginator
        page={page}
        onPaginate={page => setFiltros(prev => ({ ...prev, page }))}
        hasNext={hasNext}
        isFetching={isFetching}
      />
    </Stack>
  );
};

const Inicio = () => {
  return (
    <Stack py={4} spacing={6}>
      <TableRetiradasAluguel />
      <TableIndoParaLavanderia />
      <TableDevolucoesAluguel />
      <TableVoltandoDaLavanderia />
    </Stack>
  );
};

export default Inicio;
