import isEmpty from 'lodash/isEmpty';
import { prepareInputCurrencyValue, prepareInputDateValue } from 'utils/form';
//import { format } from 'date-fns';

export default pagamentos => {
  return !isEmpty(pagamentos)
    ? pagamentos.map(pagamento => ({
        id: pagamento.id,
        tipo: pagamento.tipo,
        valor: prepareInputCurrencyValue(pagamento.valor),
        data: prepareInputDateValue(pagamento.data)
      }))
    : [];
};
