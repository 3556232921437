import React from 'react';
import {
  Box,
  Stack,
  ButtonGroup,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  ModalBody,
  IconButton,
  Divider,
  Text,
  Tooltip
} from '@chakra-ui/react';
import { FaTimes, FaPlus } from 'react-icons/fa';
import * as yup from 'yup';
import { format } from 'date-fns';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { usePostAluguelEfetuarRetirada } from 'actions/alugueis';
import { useGetTipoPagamentoAnunciante } from 'actions/enums';
import {
  Form,
  FormTableRow,
  FormTableCell,
  FormSelect,
  FormInputGroup,
  FormInputDate,
  FormInputCurrency,
  useFormFieldArray,
  useWatch
} from 'components/form';
import { useFormContext, Controller } from 'react-hook-form';
import Select from 'components/form/base/Select';
import InputCurrency from 'components/form/base/InputCurrency';
import InputDate from 'components/form/base/InputDate';
import {
  prepareInputDateValue,
  prepareOutputCurrencyValue,
  prepareOutputDateValue
} from 'utils/form';
import { formatCurrency, formatDate } from 'utils/ui';

const validationSchema = yup.object().shape({
  pagamentos_cliente: yup
    .array()
    .of(
      yup.object().shape({
        tipo: yup.string().nullable().required().label('Tipo'),
        valor: yup.string().required().label('Valor'),
        data: yup.string().required().label('Data')
      })
    )
    .required('Deve haver ao menos um pagamento')
});

const ModalBodyForm = ({ setDraft, totalPagamentosCliente, valorAluguel }) => {
  const name = 'pagamentos_cliente';
  const { data: tipos, status: statusTipos } = useGetTipoPagamentoAnunciante();
  const { fields, append, remove } = useFormFieldArray({ name });
  const fieldArray = useWatch(name);
  const { formState: { errors }, control } = useFormContext();

  const isLoadingTipos = statusTipos === 'loading';

  const optionsTipos = React.useMemo(() => {return tipos ? tipos.data : []}, [tipos]);

  const tipoMap = React.useMemo(() => {
    return optionsTipos.reduce((acc, curr) => {
      acc[curr.value] = curr.label;
      return acc;
    }, {}) || {};
  }, [optionsTipos]);

  const [tipoValue, setTipoValue] = React.useState(null);
  const [valorValue, setValorValue] = React.useState('');
  const [dataValue, setDataValue] = React.useState(format(new Date(), 'yyyy-MM-dd'));


  const addDisabled = !tipoValue || !valorValue || !dataValue;

  const total = fieldArray.reduce((result, field) => {
    const valor = parseFloat(prepareOutputCurrencyValue(field.valor)) || 0;
    return (result += valor);
  }, 0);

  const totalPendente = valorAluguel - totalPagamentosCliente - total;

  return (
    <ModalBody width="100%">
      <FormTableRow px={4} py={2}>
        <FormTableCell>Tipo</FormTableCell>
        <FormTableCell>Valor</FormTableCell>
        <FormTableCell>Data</FormTableCell>
      </FormTableRow>
      <Divider mt={0} />
      <Stack>
        <FormTableRow p={2}>
          <FormTableCell>Valor Reserva</FormTableCell>
          {/* TODO: Esse valor é sempre 0? */}
          <FormTableCell>{formatCurrency(0)}</FormTableCell>
        </FormTableRow>
        {fields.map((field, index) => {
          return (
            <FormTableRow key={index} bg={!isEmpty(get(errors, `${name}.${index}`)) ? 'orange.100' : ''}>
              {/* <Box display="flex" alignItems="center"> */}
                <Controller
                  name={`${name}.${index}.tipo`}
                  control={control}
                  render={({ field }) => <Text display={'flex'} alignItems={'center'} fontSize="sm">{tipoMap && tipoMap[field.value]}</Text>}
                />
                {/* 
                <Controller
                  name={`${name}.${index}.id`}
                  control={control}
                  render={() => null}
                />
              </Box>*/}
              <Controller
                name={`${name}.${index}.valor`}
                control={control}
                render={({ field }) => <Text display={'flex'} alignItems={'center'} fontSize="sm">{formatCurrency(parseFloat(prepareOutputCurrencyValue(field.value)))}</Text>}
              />
              <Controller
                name={`${name}.${index}.data`}
                control={control}
                render={({ field }) => <Text display={'flex'} alignItems={'center'} fontSize="sm">{formatDate(prepareOutputDateValue(field.value))}</Text>}
              />
              <IconButton
                size="sm"
                aria-label="Sair"
                variant="ghost"
                icon={<FaTimes />}
                onClick={() => remove(index)}
              />
            </FormTableRow>
          );
        })}
        <FormTableRow>
          <Select
            isLoading={isLoadingTipos}
            options={optionsTipos}
            selectFirst={false}
            value={tipoValue}
            onChange={selected => {
              if (!selected) {
                setDraft && setDraft(false);
              } else {
                setDraft && setDraft(true);
              }
              setTipoValue(selected);
            }}
          />
          <FormInputGroup
            value={valorValue}
            onChange={e => { return setValorValue(e) }}
            inputComponent={InputCurrency}
            leftAddon="R$"
            placeholder="Valor"
          />
          <InputDate
            value={dataValue}
            onChange={e => setDataValue(e)}
            placeholder="Data"
          />

          <Tooltip label={addDisabled ? "Selecione um tipo, valor e data" : ""}>
            <IconButton
              isDisabled={addDisabled}
              size="sm"
              aria-label="Adicionar item"
              icon={<FaPlus />}
              onClick={() => {
                setDraft && setDraft(false)
                append({
                  tipo: tipoValue?.value,
                  valor: valorValue,
                  data: dataValue
                })
                setTipoValue(null);
                setValorValue('');
              }}
            />
          </Tooltip>
        </FormTableRow>
        <Box>
          <FormTableRow bg="yellow.100" p={2}>
            <FormTableCell>Total Pago</FormTableCell>
            <FormTableCell>{formatCurrency(total)}</FormTableCell>
          </FormTableRow>
          <FormTableRow bg="gray.100" p={2}>
            <FormTableCell>Total Pendente</FormTableCell>
            <FormTableCell>{formatCurrency(totalPendente)}</FormTableCell>
          </FormTableRow>
          <FormTableRow bg="gray.100" p={2}>
            <FormTableCell>Valor Aluguel</FormTableCell>
            <FormTableCell>{formatCurrency(valorAluguel)}</FormTableCell>
          </FormTableRow>
        </Box>
      </Stack>
    </ModalBody>
  );
};

const ModalEfetuarRetirada = ({
  isOpen,
  onClose,
  aluguelId,
  totalPagamentosCliente = 0,
  valorAluguel = 0
}) => {

  const { mutateAsync: efetuarRetirada, status } = usePostAluguelEfetuarRetirada(
    aluguelId
  );
  const [draftPagamento, setDraftPagamento] = React.useState(false);

  const isSubmitting = status === 'loading';

  const onSubmit = async data => {
    //try {
    const pagamentos_cliente = data.pagamentos_cliente.map(pagamento => ({
      tipo: pagamento.tipo ? pagamento.tipo : null,
      valor: prepareOutputCurrencyValue(pagamento.valor),
      data: prepareOutputDateValue(pagamento.data)
    }));

    await efetuarRetirada({ pagamentos_cliente });

    onClose();
    //} catch (er) {
    //  toastList({ messages: er.errors, status: 'error' });
    //}
  };

  const defaultValues = {
    pagamentos_cliente: [
    ]
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      size="lg"
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Pagamento</ModalHeader>
        <ModalCloseButton />
        <Form
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          defaultValues={defaultValues}
          spacing={0}
          align="flex-end"
        >
          <ModalBodyForm
            setDraft={setDraftPagamento}
            totalPagamentosCliente={totalPagamentosCliente}
            valorAluguel={valorAluguel}
          />
          <ModalFooter>
          
            <ButtonGroup size="sm">
            <Tooltip label={draftPagamento?"Adicione ou limpe o pagamento":""}>    
              <Button
                type="submit"
                loadingText="Efetuando Retirada"
                colorScheme="blue"
                variant="solid"
                isDisabled={draftPagamento}
                isLoading={isSubmitting}
              >
                Efetuar Retirada
              </Button>
              </Tooltip>
            </ButtonGroup>
            
          </ModalFooter>
        </Form>
      </ModalContent>
    </Modal>
  );
};

export default ModalEfetuarRetirada;
