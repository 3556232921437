import React from 'react';
import { Stack, Box, Text, Button, useDisclosure } from '@chakra-ui/react';

import {
  useGetAnuncianteTotalPago,
  useGetAnunciantePagamentos
} from 'actions/anunciantes';
import {
  DisplayText,
  Paginator,
  Table,
  TableHead,
  TableBodyZebra,
  TableRow,
  TableHeader,
  TableData
} from 'components/ui';
import { formatCurrency, formatDate } from 'utils/ui';

import ModalVerPagamentos from './ModalVerPagamentos';

const TableRowPagamento = ({ item }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    id,
    item_referencia,
    aluguel_data_evento,
    valor_previsto_cliente,
    total_pagamento_anunciante,
    pagamentos_anunciante
  } = item;

  return (
    <>
      <TableRow key={id}>
        <TableData>{item_referencia}</TableData>
        <TableData>
          {aluguel_data_evento ? formatDate(aluguel_data_evento) : ''}
        </TableData>
        <TableData>
          {valor_previsto_cliente
            ? formatCurrency(valor_previsto_cliente)
            : '—'}
        </TableData>
        <TableData>
          {total_pagamento_anunciante
            ? formatCurrency(total_pagamento_anunciante)
            : '—'}
        </TableData>
        <TableData>
          <Button size="xs" variant="outline" onClick={onOpen}>
            Ver
          </Button>
        </TableData>
      </TableRow>
      <ModalVerPagamentos
        isOpen={isOpen}
        onClose={onClose}
        pagamentos={pagamentos_anunciante}
      />
    </>
  );
};

const TabContentPagamentos = ({ id }) => {
  const [page, setPage] = React.useState(1);
  const {
    data: totalPago,
    status: statusTotalPago
  } = useGetAnuncianteTotalPago({ id });
  const {
    data: pagamentos,
    status,
    hasNext,
    isFetching
  } = useGetAnunciantePagamentos({ id, page });

  if (status === 'loading' || statusTotalPago === 'loading')
    return <DisplayText>Carregando...</DisplayText>;

  return (
    <Stack>
      <Stack as="dl" isInline spacing={4} fontSize="xl" justify="center">
        <Box as="dt">Total Pago</Box>
        <Box as="dd">
          <Text as="abbr" cursor="help" textDecor="underline dotted">
            {formatCurrency(totalPago.data.valor_total)}
          </Text>
        </Box>
      </Stack>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeader>Item</TableHeader>
            <TableHeader>Data Evento</TableHeader>
            <TableHeader>Valor Total</TableHeader>
            <TableHeader>Valor Anunciante</TableHeader>
            <TableHeader>Pagamento</TableHeader>
          </TableRow>
        </TableHead>
        <TableBodyZebra>
          {pagamentos.data.results.length === 0 ? (
            <TableRow>
              <TableData colSpan="5">Nenhuma informação disponível</TableData>
            </TableRow>
          ) : (
            pagamentos.data.results.map(item => (
              <TableRowPagamento key={item.id} item={item} />
            ))
          )}
        </TableBodyZebra>
      </Table>
      <Paginator page={page} onPaginate={setPage} hasNext={hasNext} isFetching={isFetching}/>
    </Stack>
  );
};

export default TabContentPagamentos;
