import React from 'react';
import {
  useForm,
  useFieldArray,
  useFormContext,
  //FormContext,
  Controller,
  FormProvider
} from 'react-hook-form';
import {
  Link as RouterLink
} from 'react-router-dom';
import {
  Alert,
  AlertIcon,
  AlertDescription,
  Box,
  Grid,
  Stack,
  Text,
  Link,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  FormControl as ChakraFormControl,
  FormLabel as ChakraFormLabel,
  Divider,
  Checkbox,
  useMergeRefs
} from '@chakra-ui/react';

import { yupResolver } from '@hookform/resolvers/yup';

import { DevTool } from "@hookform/devtools";

import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import map from 'lodash/map';

import Dropzone from './base/Dropzone';
import Input from './base/Input';
import InputCurrency from './base/InputCurrency';
import InputMask from './base/InputMask';
import InputPhone from './base/InputPhone';
import InputDate from './base/InputDate';
import Radio from './base/Radio';
import Select from './base/Select';
import Textarea from './base/Textarea';

// eslint-disable-next-line no-unused-vars
export const FormInput = React.forwardRef(({name, ...rest}, ref) => {
  const { register } = useFormContext();
  return (
    <Input {...register(name)}
      {...rest}
    />
  );
});

export const FormInputMask = ({ defaultValue = '', name, ...rest }) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => <InputMask {...rest} {...field} />}
      defaultValue={defaultValue}
    />
  );
};

export const FormInputPhone = ({ defaultValue = '', name, ...rest }) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => <InputPhone {...rest} {...field} />}
      defaultValue={defaultValue}
    />
  );
};

export const FormInputDate = ({ defaultValue = '', name, ...rest }) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => <InputDate {...rest} {...field} />}
      defaultValue={defaultValue}
    />
  );
};

export const FormInputCurrency = ({ name, ...rest }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => <InputCurrency {...rest} {...field} />}
    />
  );
};

export const FormSelect = ({
  name,
  isLoading,
  options,
  selectFirst = true,
  initialValue = null,
  ...rest
}) => {
  const { control, getValues, setValue } = useFormContext();

  // O ideal não é utilizar setValue() e sim reset()
  // Contudo reset() não funciona caso tenham muitos selects no form
  React.useEffect(() => {
    if (initialValue && !getValues(name)) {
      setValue(name, initialValue);
    } else if (
      selectFirst &&
      !isLoading &&
      !isEmpty(options) &&
      !getValues(name)
    ) {
      setValue(name, options[0]);
    }
  }, [
    getValues,
    initialValue,
    isLoading,
    name,
    options,
    selectFirst,
    setValue
  ]);

  // React.useEffect(() => {
  //   if (initialValue && !getValues(name)) {
  //     reset({ ...getValues(), [name]: initialValue });
  //   } else if (selectFirst && !isLoading && !isEmpty(options) && !getValues(name)) {
  //     reset({ ...getValues(), [name]: options[0] });
  //   }
  // }, [initialValue, name, reset, getValues, selectFirst, isLoading, options]);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => <Select options={options} isLoading={isLoading} {...rest} {...field} />}
    />
  );
};

export const FormRadio = ({ name, ...rest }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => <Radio {...rest} {...field} />}
    />
  );
};

export const FormDropzone = 
  ({ name, ...rest }) => {
  const { control, setValue } = useFormContext();

  const handleOnDrop = React.useCallback(
    file => {
      setValue(name, file);
    },
    [name, setValue]
  );

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => <Dropzone onDrop={handleOnDrop} {...rest} {...field} />}
    />
  );
};

// eslint-disable-next-line no-unused-vars
export const FormTextarea = React.forwardRef(({name, ...rest}, ref) => {
  const { register } = useFormContext();
  const { regRef, ...regRest} = register(name);
  const mergedRefs = useMergeRefs(regRef, ref);
  return (
    <Textarea
    ref={mergedRefs}
      {...regRest}
      {...rest}
    />
  );
});

export const FormCheckbox = React.forwardRef(({name, ...rest}, ref) => {
  const { register } = useFormContext();
  const {regRef, ...regRest} = register(name);
  const mergedRefs = useMergeRefs(regRef, ref);
  return <Checkbox ref={mergedRefs}{...regRest} size="sm" {...rest} />;
});

export const FormInputGroup = ({
  inputComponent: InputComponent = FormInput,
  leftAddon,
  rightAddon,
  ...rest
}) => {
  let inputProps = null;
  if (leftAddon) inputProps = { roundedLeft: 0 };
  if (rightAddon) inputProps = { roundedRight: 0 };

  return (
    <InputGroup size="sm">
      {leftAddon && <InputLeftAddon>{leftAddon}</InputLeftAddon>}
      <InputComponent {...inputProps} {...rest} />
      {rightAddon && <InputRightAddon>{rightAddon}</InputRightAddon>}
    </InputGroup>
  );
};

/////////////////////////////////////////////////////////////////////

const FormErrors = () => {
  const { formState: { errors } } = useFormContext();

  if (isEmpty(errors)) return null;

  return (
    <Alert status="warning">
      <AlertIcon />
      <AlertDescription color="yellow.700" fontSize="sm">
        {map(errors, (error, key) => {
          return isArray(error) ? (
            error.map(innerErrors =>
              map(innerErrors, (innerError, innerErrorKey) => (
                <Text key={innerErrorKey}>{innerError.message}</Text>
              ))
            )
          ) : (
            <Text key={key}>{error.message}</Text>
          );
        })}
      </AlertDescription>
      {/* <CloseButton position="absolute" right="8px" top="8px" /> */}
    </Alert>
  );
};

const MessageComponent = ({message}) => {
  if (message.tipo === 'conflito_aluguel') return <Text>O item {message.referencia} conflita com o aluguel <Link as={RouterLink} to={`/aluguel/${message.id}`}>{message.id}</Link>.</Text>
  if (message.tipo === 'conflito_lavanderia') return <Text>O item {message.referencia} conflita com a lavanderia <Link as={RouterLink} to={`/lavanderia/${message.id}`}>{message.id}</Link>.</Text>
  return <Text>{message.message||"Erro desconhecido"}</Text>
}

export const Form = ({
  defaultValues,
  onSubmit,
  validationSchema,
  children,
  ...rest
}) => {
  const params = { defaultValues };
  if (validationSchema) params.resolver = yupResolver(validationSchema);
  const methods = useForm(params);

  const handleSubmit = async data => {
    try {
      return await onSubmit(data)
    } catch (error) {
      const errors = error.errors|| {}
      for (let e in errors){
        let message = errors[e]
        if (isObject(message)){
          message = <MessageComponent message={message}/>
          if (process.env.NODE_ENV === 'development') {
            message = Object.assign({trim: () => 'Error component'}, message);
          }
        }
        if (e){
          methods.setError(e, {type:'server', message:message})
        } else {
          methods.setError("non_field_errors", {type:'server', message:message})
        }
      }
    }
  }

  return (
    <FormProvider {...methods}>
      <DevTool control={methods.control} placement="top-right" />
      <FormErrors />
      <Stack
        spacing={4}
        as="form"
        align="start"
        noValidate
        onSubmit={methods.handleSubmit(handleSubmit)}
        {...rest}
      >
        {children}
      </Stack>
      
    </FormProvider>
  );
};

export const FormFieldset = ({ label, children, mb, mr, ...rest }) => {
  return (
    <Box width="100%" as="fieldset" mb={mb} mr={mr} {...rest}>
      {label && (
        <Text fontSize="lg" marginBottom={0}>
          {label}
        </Text>
      )}
      <Divider />
      <Stack spacing={4} mt={6}>
        {children}
      </Stack>
    </Box>
  );
};

export const FormControl = ({ children, isInvalid, ...rest }) => {
  return (
    <ChakraFormControl
      as={Grid}
      gridGap={[1, 1, 4]}
      gridTemplateColumns={['1fr', '1fr', '200px 400px']}
      alignItems="center"
      className={isInvalid?'invalid':''}
      {...rest}
    >
      {children}
    </ChakraFormControl>
  );
};

export const FormGroupButton = ({ children, ...rest }) => {
  return (
    <Grid
      gridGap={[1, 1, 4]}
      gridTemplateColumns={['1fr', '1fr', '200px 1fr']}
      alignItems="center"
      {...rest}
    >
      {React.Children.toArray(children).map(child =>
        React.cloneElement(child, { style: { gridColumnStart: 2 } })
      )}
    </Grid>
  );
};

export const FormLabel = ({ children, ...rest }) => {
  return (
    <ChakraFormLabel
      width="100%"
      textAlign={['start', 'start', 'end']}
      fontSize="sm"
      p={0}
      my={0}
      {...rest}
    >
      {children}
    </ChakraFormLabel>
  );
};

export const FormTableRow = props => (
  <Grid templateColumns="3fr 2fr 165px 32px" gap={1} {...props} />
);

export const FormTableCell = props => (
  <Text fontSize="sm" fontWeight="semibold" {...props} />
);

export const FormControlInput = ({
  label,
  name,
  leftAddon,
  rightAddon,
  mb,
  mr,
  ...rest
}) => {
  const { formState: { errors } } = useFormContext();

  return (
    <FormControl mb={mb} mr={mr} isInvalid={!isEmpty(errors[name])}>
      <FormLabel>{label}</FormLabel>
      {leftAddon || rightAddon ? (
        <FormInputGroup
          name={name}
          aria-label={label}
          leftAddon={leftAddon}
          rightAddon={rightAddon}
          {...rest}
        />
      ) : (
        <FormInput name={name} aria-label={label} {...rest} />
      )}
    </FormControl>
  );
};

export const FormControlInputMask = ({ mb, mr, label, name, ...rest }) => {
  const { formState: { errors } } = useFormContext();

  return (
    <FormControl mb={mb} mr={mr} isInvalid={!isEmpty(errors[name])}>
      <FormLabel>{label}</FormLabel>
      <FormInputMask name={name} aria-label={label} {...rest} />
    </FormControl>
  );
};

export const FormControlInputPhone = ({ mb, mr, label, name, ...rest }) => {
  const { formState: { errors } } = useFormContext();

  return (
    <FormControl mb={mb} mr={mr} isInvalid={!isEmpty(errors[name])}>
      <FormLabel>{label}</FormLabel>
      <FormInputPhone name={name} aria-label={label} {...rest} />
    </FormControl>
  );
};

export const FormControlInputDate = ({ mb, mr, label, name, ...rest }) => {
  const { formState: { errors } } = useFormContext();

  return (
    <FormControl mb={mb} mr={mr} isInvalid={!isEmpty(errors[name])}>
      <FormLabel>{label}</FormLabel>
      <FormInputDate name={name} aria-label={label} {...rest} />
    </FormControl>
  );
};

export const FormControlInputCurrency = ({ mb, mr, label, name, ...rest }) => {
  const { formState: { errors } } = useFormContext();

  return (
    <FormControl mb={mb} mr={mr} isInvalid={!isEmpty(errors[name])}>
      <FormLabel>{label}</FormLabel>
      <FormInputCurrency name={name} aria-label={label} {...rest} />
    </FormControl>
  );
};

export function FormControlSelect({ mb, mr, label, name, ...rest }) {
  const { formState: { errors } } = useFormContext();

  return (
    <FormControl mb={mb} mr={mr} isInvalid={!isEmpty(errors[name])}>
      <FormLabel>{label}</FormLabel>
      <FormSelect name={name} aria-label={label} {...rest} />
    </FormControl>
  );
}

export function FormControlRadio({ label, name, mb, mr, ...rest }) {
  const { formState: { errors } } = useFormContext();

  return (
    <FormControl
      alignItems="start"
      mb={mb}
      mr={mr}
      isInvalid={!isEmpty(errors[name])}
    >
      <FormLabel>{label}</FormLabel>
      <FormRadio name={name} aria-label={label} {...rest} />
    </FormControl>
  );
}

export function FormControlCheckbox({ label, name, mb, mr, ...rest }) {
  const { formState: { errors } } = useFormContext();

  return (
    <FormControl
      alignItems="start"
      mb={mb}
      mr={mr}
      isInvalid={!isEmpty(errors[name])}
    >
      <FormLabel>{label}</FormLabel>
      <FormCheckbox name={name} aria-label={label} {...rest} />
    </FormControl>
  );
}

export function FormControlDropzone({ label, name, mb, mr, ...rest }) {
  const { formState: { errors } } = useFormContext();

  return (
    <FormControl
      alignItems="start"
      mb={mb}
      mr={mr}
      isInvalid={!isEmpty(errors[name])}
    >
      <FormLabel >{label}</FormLabel>
      <FormDropzone name={name} aria-label={label} {...rest} />
    </FormControl>
  );
}

export const FormControlTextarea = ({ mb, mr, label, name, ...rest }) => {
  const { formState: { errors } } = useFormContext();

  return (
    <FormControl mb={mb} mr={mr} isInvalid={!isEmpty(errors[name])}>
      <FormLabel>{label}</FormLabel>
      <FormTextarea name={name} aria-label={label} {...rest} />
    </FormControl>
  );
};

/////////////////////////////////////////////////////////////////////

export {
  Dropzone,
  Input,
  InputCurrency,
  InputGroup,
  InputMask,
  InputPhone,
  InputDate,
  Radio,
  Select,
  Textarea
};

/////////////////////////////////////////////////////////////////////

export function useFormFieldArray(props) {
  const { control } = useFormContext();
  return useFieldArray({ control, ...props });
}

export function useWatch(...args) {
  const { watch } = useFormContext();
  // TODO: verificar chamadas pra lista
  return watch(...args);
}
