import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Stack, Box } from '@chakra-ui/react';
import reduce from 'lodash/reduce';

import {
  FormTableRow,
  FormTableCell,
  FormControl
} from 'components/form';
import { prepareOutputCurrencyValue } from 'utils/form';
import { formatCurrency } from 'utils/ui';

import FormControlItens from './FormControlItens';
import FormControlPagamento from './FormControlPagamento';

const FormControlItensPagamentos = ({ aluguel, setDraftItem, setDraftPagamento, ...rest }) => {
  const { watch } = useFormContext();

  const [fieldsItens, fieldsPagamento] = watch(['aluguel_itens', 'pagamentos_cliente']);

  const totalItens = reduce(
    fieldsItens,
    (result, aluguelItem) => {
      const valorNegociado =
        parseFloat(prepareOutputCurrencyValue(aluguelItem.valor_negociado)) ||
        0;
      const valorLavanderia =
        parseFloat(
          prepareOutputCurrencyValue(aluguelItem.aluguel_item_lavanderia.valor)
        ) || 0;
      return (result += valorNegociado + valorLavanderia);
    },
    0
  );

  const totalPagamento = reduce(
    fieldsPagamento,
    (result, field) => {
      const valor = parseFloat(prepareOutputCurrencyValue(field.valor)) || 0;
      return (result += valor);
    },
    0
  );

  // TODO: Corrigir totais quando não há itens/pagamentos
  const totalPendente = totalItens > 0 ? totalItens - totalPagamento : 0;

  return (
    <Stack spacing={4} align="start" {...rest}>
      <FormControlItens
        name="aluguel_itens"
        total={totalItens}
        setDraft={setDraftItem}
        {...{ aluguel }}
      />
      <Box>
      <FormControlPagamento
        name="pagamentos_cliente"
        total={totalPagamento}
        setDraft={setDraftPagamento}
        {...{ aluguel }}
      />
      <FormControl gridTemplateColumns={['1fr', '1fr', '200px 600px']}>
        <Box />
        <FormTableRow bg="gray.100" px={4} py={2}>
          <FormTableCell fontWeight="normal">Total Pendente</FormTableCell>
          <FormTableCell fontWeight="normal">{formatCurrency(totalPendente)}</FormTableCell>
        </FormTableRow>
      </FormControl>
      </Box>
    </Stack>
  );
};

export default FormControlItensPagamentos;
