import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Stack } from '@chakra-ui/react';

import { useGetItensReservas } from 'actions/itens';
import {
  DisplayText,
  DisplayErrors,
  Paginator,
  Table,
  TableHead,
  TableBodyZebra,
  TableRow,
  TableHeader,
  TableData
} from 'components/ui';
import { formatCurrency, formatDate } from 'utils/ui';

const TableRowReserva = ({ value }) => {
  const history = useNavigate();
  const {
    id,
    aluguel,
    aluguel_cliente_none,
    aluguel_data_retirada,
    aluguel_data_evento,
    aluguel_data_devolucao,
    valor_negociado,
    has_lavanderia
  } = value;

  return (
    <TableRow key={id} onClick={() => history(`/aluguel/${aluguel}`)}>
      <TableData>{aluguel_cliente_none}</TableData>
      <TableData>
        {aluguel_data_retirada ? formatDate(aluguel_data_retirada) : '—'}
      </TableData>
      <TableData>
        {aluguel_data_evento ? formatDate(aluguel_data_evento) : '—'}
      </TableData>
      <TableData>
        {aluguel_data_devolucao ? formatDate(aluguel_data_devolucao) : '—'}
      </TableData>
      <TableData>
        {valor_negociado ? formatCurrency(valor_negociado) : '—'}
      </TableData>
      <TableData>{has_lavanderia ? 'Sim' : 'Não'}</TableData>
      <TableData />
    </TableRow>
  );
};

const TabContentReservas = () => {
  const { id } = useParams();
  const [page, setPage] = React.useState(1);
  const {
    data: reservas,
    hasNext,
    status,
    error,
    isFetching
  } = useGetItensReservas({ id, page });

  if (status === 'loading') return <DisplayText>Carregando...</DisplayText>;

  if (status === 'error') return <DisplayErrors errors={error.errors} />;

  return (
    <Stack>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeader>Cliente</TableHeader>
            <TableHeader>Data Retirada</TableHeader>
            <TableHeader>Data Evento</TableHeader>
            <TableHeader>Data Devolução</TableHeader>
            <TableHeader>Valor Aluguel</TableHeader>
            <TableHeader>Lavanderia</TableHeader>
          </TableRow>
        </TableHead>
        <TableBodyZebra>
          {reservas.data.results.length === 0 ? (
            <TableRow>
              <TableData colSpan="6">Nenhuma informação disponível</TableData>
            </TableRow>
          ) : (
            reservas.data.results.map(reserva => (
              <TableRowReserva key={reserva.id} value={reserva} />
            ))
          )}
        </TableBodyZebra>
      </Table>
      <Paginator page={page} onPaginate={setPage} hasNext={hasNext} isFetching={isFetching}/>
    </Stack>
  );
};

export default TabContentReservas;
