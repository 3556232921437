import React from 'react';
import {
  useNavigate,
  Link as RouterLink
} from 'react-router-dom';
import {
  Menu,
  IconButton,
  MenuList,
  MenuButton,
  MenuDivider,
  useDisclosure
} from '@chakra-ui/react';
import { FaChevronDown, FaPen, FaTrash } from 'react-icons/fa';

import { useDeleteLavanderia } from 'actions/lavanderias';
import { ConfirmDialog, IconMenuItem } from 'components/ui';
import { useToastList } from 'customHooks';

const AcoesButton = ({ lavanderia }) => {
  const { id } = lavanderia;
  const history = useNavigate();
  const toastList = useToastList();

  const {
    isOpen: isOpenConfirmacaoRemover,
    onClose: onCloseConfirmacaoRemover,
    onOpen: onOpenConfirmacaoRemover
  } = useDisclosure();

  const {mutateAsync: apagar, status: statusApagar } = useDeleteLavanderia(id);

  const apagarLavanderia = async () => {
    try {
      await apagar();
      history(`/lavanderias`);
    } catch (er) {
      toastList({ messages: er.errors, status: 'error' });
    }
  };

  return (
    <>
      <Menu placement="bottom-end">
        <MenuButton
          as={IconButton}
          size="sm"
          variant="outline"
          icon={<FaChevronDown/>}
        />
        <MenuList minWidth="150px">
          <IconMenuItem icon={FaPen} as={RouterLink} to={`form`}>
            Editar
          </IconMenuItem>
          <MenuDivider />
          <IconMenuItem icon={FaTrash} onClick={onOpenConfirmacaoRemover}>
            Remover
          </IconMenuItem>
        </MenuList>
      </Menu>
      <ConfirmDialog
        isLoading={statusApagar === 'loading'}
        isOpen={isOpenConfirmacaoRemover}
        onClose={onCloseConfirmacaoRemover}
        onConfirm={apagarLavanderia}
        zIndexOverlay="modal"
        title="Remover Lavanderia"
        confirmButtonText="Sim"
        cancelButtonText="Não"
        colorScheme={'red'}
      >
        Deseja remover esse Lavanderia?
      </ConfirmDialog>
    </>
  );
};

export default AcoesButton;
