import React from 'react';
import { useFormContext, useFieldArray, Controller } from 'react-hook-form';
import {
  Text,
  Box,
  Stack,
  //Button,
  IconButton,
  Divider,
  Checkbox,
  InputGroup,
  InputLeftAddon,
  Tooltip
} from '@chakra-ui/react';
import { FaTimes, FaPlus } from 'react-icons/fa';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
//import find from 'lodash/find';
import Select from 'components/form/base/Select';
import InputCurrency from 'components/form/base/InputCurrency';

import { useGetOptionsItens } from 'actions/options';
import {
  FormTableRow,
  FormTableCell,
  FormControl,
  FormLabel,
  FormInputGroup,
  //FormSelect,
  //FormInputCurrency
} from 'components/form';
import { SelectOptionItem } from 'components/ui';
import { formatCurrency } from 'utils/ui';
//import { prepareInputCurrencyValue, prepareOutputCurrencyValue } from 'utils/form';
import { prepareOutputCurrencyValue } from 'utils/form';



const FormTableRowItemReadOnly = ({
  name,
  field, // eslint-disable-line no-unused-vars 
  index,
  onRemove,
  itens, // eslint-disable-line no-unused-vars 
  ...rest
}) => {
  const {
    formState: { errors },
    control
  } = useFormContext();

  return (
    <FormTableRow pl={2} {...rest} py={1} bg={!isEmpty(get(errors, `${name}.${index}`)) ? 'orange.100' : ''}>
      <Box display="flex" alignItems="center">
        <Controller
          name={`${name}.${index}.item_referencia`}
          control={control}
          render={({ field }) => <Text display={'flex'} alignItems={'center'} fontSize="sm">{field.value}</Text>}
        />
        <Controller
          name={`${name}.${index}.item`}
          control={control}
          render={() => null}
        />
        <Controller
          name={`${name}.${index}.id`}
          control={control}
          render={() => null}
        />
        <Controller
          name={`${name}.${index}.aluguel_item_lavanderia.id`}
          control={control}
          render={() => null}

        />
      </Box>
      <Controller
        name={`${name}.${index}.valor_negociado`}
        control={control}
        render={({ field }) => <Text display={'flex'} alignItems={'center'} fontSize="sm">{formatCurrency(parseFloat(prepareOutputCurrencyValue(field.value)))}</Text>}
      />


      <Controller
        name={`${name}.${index}.aluguel_item_lavanderia.valor`}
        control={control}
        render={({ field }) => <Text display={'flex'} alignItems={'center'} fontSize="sm">{field.value ? formatCurrency(parseFloat(prepareOutputCurrencyValue(field.value))) : '-'}</Text>}
      />

      <IconButton
        size="sm"
        aria-label="Remover item"
        variant="ghost"
        icon={<FaTimes />}
        onClick={() => onRemove(index)}
      />
    </FormTableRow>
  );
};

const FormTableRowItem = ({
  onAdd,
  itens,
  isLoadingItens,
  setDraft,
  ...rest
}) => {

  const [itemValue, setItemValue] = React.useState(null);
  const [temValor, setTemValor] = React.useState('');
  const [valorNegociado, setValorNegociado] = React.useState('');
  const [valorLavanderia, setValorLavanderia] = React.useState('');

  //const lavanderiaDisabled = React.useMemo(() => itemValue ? !itemValue.valorLavanderia : !itemValue, [itemValue]);

  const [sugerirValor, setSugerirValor] = React.useState(false);
  //console.log('sugerirValor', setSugerirValor,temValor)


  //React.useEffect(() => {setSugerirValor(Boolean(!lavanderiaDisabled && temValor))}, [lavanderiaDisabled, temValor]);


  const optionsItens = React.useMemo(
    () =>
      itens
        ? itens.data.map(item => ({
          value: item.id,
          label: item.referencia,
          thumbnail: item.url_thumbnail,
          valorAluguel: item.valor_aluguel,
          valorLavanderia: item.valor_lavanderia
        }))
        : [],
    [itens]
  );

  const addDisabled = !itemValue || !valorNegociado
  return (
    <FormTableRow {...rest}>
      <Select
        isLoading={isLoadingItens}
        options={optionsItens}
        components={{ Option: SelectOptionItem }}
        selectFirst={false}
        value={itemValue}
        onChange={selected => {

          if (!selected) {
            setDraft && setDraft(false);
          } else {
            setDraft && setDraft(true);
          }
          setItemValue(selected);
          if (selected?.valorAluguel) {
            setValorNegociado(selected?.valorAluguel.replace('.', ','));
          } else {
            setValorNegociado('');
          }
          setSugerirValor(false);
          setValorLavanderia('');
          if (selected?.valorLavanderia) {
            setTemValor(true);
          } else {
            setTemValor(false);
          }


        }}
      />
      <FormInputGroup
        value={valorNegociado}
        onChange={e => { return setValorNegociado(e) }}
        inputComponent={InputCurrency}
        leftAddon="R$"
        placeholder="Valor"
      />
      <InputGroup size="sm">
        <InputLeftAddon>
          <Checkbox
            isChecked={sugerirValor}
            isDisabled={!itemValue?.valorLavanderia}
            bg={!sugerirValor && 'white'}
            onChange={(e) => {
              setSugerirValor(e.target.checked);
              if (e.target.checked) {
                setValorLavanderia(itemValue?.valorLavanderia.replace('.', ','));
              } else {
                setValorLavanderia('');
              }
            }}
          />
          <Text ml={1}>R$</Text>
        </InputLeftAddon>
        <InputCurrency
          //isInvalid={!valorLavanderia && sugerirValor}
          placeholder={sugerirValor ? 'Valor' : ''}
          isDisabled={!temValor}
          bg={!itemValue?.valorLavanderia ? 'gray.100' : ''}
          value={valorLavanderia}
          onChange={e => { if (e) { setSugerirValor(true) } else { setSugerirValor(false) } return setValorLavanderia(e) }}
        />
      </InputGroup>
      <Tooltip label={addDisabled ? "Selecione um item e valor" : ""}>
        <IconButton
          isDisabled={addDisabled}
          size="sm"
          aria-label="Adicionar item"
          icon={<FaPlus />}
          onClick={() => {
            setDraft && setDraft(false)
            onAdd({
              item: itemValue?.value,
              item_referencia: itemValue?.label,
              valor_negociado: valorNegociado,
              aluguel_item_lavanderia: {
                valor: valorLavanderia
              }
            })
            setItemValue(null);
            setTemValor('');
            setValorNegociado('');
            setValorLavanderia('');
          }}
        />
      </Tooltip>
    </FormTableRow>
  );
};

const FormControlItens = ({ name, total, mb, mr, setDraft }) => {
  const { data: itens, status: statusItens } = useGetOptionsItens();
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({ control, name });
  //console.log('fields', fields, control)


  return (
    <FormControl
      mb={mb}
      mr={mr}
      gridTemplateColumns={['1fr', '1fr', '200px 600px']}
      alignItems="start"
    >
      <FormLabel>Itens</FormLabel>
      <Box>
        <FormTableRow pl={2} py={2} pt={0}>
          <FormTableCell>Item</FormTableCell>
          <FormTableCell>Aluguel</FormTableCell>
          <FormTableCell>Lavanderia</FormTableCell>
        </FormTableRow>
        <Divider mt={0} />
        <Stack>
          {fields.map((field, index) => (
            <FormTableRowItemReadOnly
              key={field.id}
              name={name}
              field={field}
              index={index}
              onRemove={remove}
              itens={itens}
            />
          ))}
          <FormTableRowItem
            isLoadingItens={statusItens === 'loading'}
            itens={itens}
            onAdd={(item) => append(item)}
            setDraft={setDraft}
          />

          <FormTableRow bg="green.100" px={4} py={2}>
            <FormTableCell>Total</FormTableCell>
            <FormTableCell>{formatCurrency(total)}</FormTableCell>
          </FormTableRow>
        </Stack>
      </Box>
    </FormControl>
  );
};

export default FormControlItens;
