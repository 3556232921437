import React from 'react';
import { useFormContext, useFieldArray, Controller } from 'react-hook-form';
import { Box, Stack, Button, IconButton, Divider, Text, Tooltip } from '@chakra-ui/react';
import { FaTimes, FaPlus } from 'react-icons/fa';
import { format } from 'date-fns';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import find from 'lodash/find';
import { prepareOutputCurrencyValue, prepareOutputDateValue } from 'utils/form';
import Select from 'components/form/base/Select';
import InputCurrency from 'components/form/base/InputCurrency';
import InputDate from 'components/form/base/InputDate';

import { useGetTipoPagamentoClientes } from 'actions/enums';
import {
  FormTableRow,
  FormTableCell,
  FormControl,
  FormLabel,
  FormInputGroup,
  FormSelect,
  FormInputCurrency,
  FormInputDate
} from 'components/form';
import { prepareInputDateValue } from 'utils/form';
import { formatCurrency, formatDate} from 'utils/ui';


const FormTableRowPagamentoReadOnly = ({
  name,
  field,
  index,
  onRemove,
  tipos,
  //isLoadingTipos,
  //defaultValueTipo,
  ...rest
}) => {
  const { formState: { errors }, control } = useFormContext();

  const tipoMap = React.useMemo(() => {
  return tipos?.reduce((acc, curr) => {
    acc[curr.value] = curr.label;
    return acc;
  }, {}) || {};
  }, [tipos]);

  return (
    <FormTableRow pl={2} {...rest} bg={!isEmpty(get(errors, `${name}.${index}`)) ? 'orange.100' : ''}>
      <Box display="flex" alignItems="center">
        <Controller
          name={`${name}.${index}.tipo`}
          control={control}
          render={({ field }) => <Text display={'flex'} alignItems={'center'} fontSize="sm">{tipoMap&&tipoMap[field.value]}</Text>}
        />
        <Controller
          name={`${name}.${index}.id`}
          control={control}
          render={() => null}
        />
      </Box>
      <Controller
        name={`${name}.${index}.valor`}
        control={control}
        render={({ field }) => <Text display={'flex'} alignItems={'center'} fontSize="sm">{formatCurrency(parseFloat(prepareOutputCurrencyValue(field.value)))}</Text>}
      />
      <Controller
        name={`${name}.${index}.data`}
        control={control}
        render={({ field }) => <Text display={'flex'} alignItems={'center'} fontSize="sm">{formatDate(prepareOutputDateValue(field.value))}</Text>}
      />
      <IconButton
        size="sm"
        aria-label="Sair"
        variant="ghost"
        icon={<FaTimes/>}
        onClick={() => onRemove(index)}
      />
    </FormTableRow>
  );
};

const FormTableRowPagamento = ({
  name,
  field,
  index,
  onRemove,
  tipos,
  isLoadingTipos,
  setDraft,
  onAdd,
  ...rest
}) => {

  const [tipoValue, setTipoValue] = React.useState(null);
  const [valorValue, setValorValue] = React.useState('');
  const [dataValue, setDataValue] = React.useState(format(new Date(), 'yyyy-MM-dd'));
  

  const addDisabled = !tipoValue || !valorValue || !dataValue;
  return (
    <FormTableRow {...rest}>
      <Select
        isLoading={isLoadingTipos}
        options={tipos}
        selectFirst={false}
        value={tipoValue}
        onChange={selected => {
          if (!selected) {
            setDraft && setDraft(false);
          } else {
            setDraft && setDraft(true);
          }
          setTipoValue(selected);
        }}
      />
      <FormInputGroup
        value={valorValue}
        onChange={e => { return setValorValue(e) }}
        inputComponent={InputCurrency}
        leftAddon="R$"
        placeholder="Valor"
      />
      <InputDate
        value={dataValue}
        onChange={e => setDataValue(e)}
        placeholder="Data"
      />
      
      <Tooltip label={addDisabled ? "Selecione um tipo, valor e data" : ""}>
        <IconButton
          isDisabled={addDisabled}
          size="sm"
          aria-label="Adicionar item"
          icon={<FaPlus />}
          onClick={() => {
            setDraft && setDraft(false)
            onAdd({
              tipo: tipoValue?.value,
              valor: valorValue,
              data: dataValue
            })
            setTipoValue(null);
            setValorValue('');
          }}
        />
      </Tooltip>
    </FormTableRow>
  );
};

const FormControlPagamento = ({ name, aluguel, total, mb, mr, setDraft }) => {
  const { data: tipos, status: statusTipos } = useGetTipoPagamentoClientes();
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({ control, name });

  const optionsTipos = tipos ? tipos.data : [];

  return (
    <FormControl
      mb={mb}
      mr={mr}
      gridTemplateColumns={['1fr', '1fr', '200px 600px']}
      alignItems="start"
    >
      <FormLabel>Pagamento</FormLabel>
      <Box>
        <FormTableRow pl={2} py={2} pt={0}>
          <FormTableCell>Tipo</FormTableCell>
          <FormTableCell>Valor</FormTableCell>
          <FormTableCell>Data</FormTableCell>
        </FormTableRow>
        <Divider mt={0} />
        <Stack>
          {fields.map((field, index) => {
            return (
              <FormTableRowPagamentoReadOnly
                key={field.id}
                name={name}
                field={field}
                index={index}
                onRemove={remove}
                isLoadingTipos={statusTipos === 'loading'}
                tipos={optionsTipos}
                defaultValueTipo={get(
                  aluguel,
                  `data.pagamentos_cliente.${index}.tipo`
                )}
              />
            );
          })}
          <FormTableRowPagamento
            isLoadingItens={statusTipos === 'loading'}
            tipos={optionsTipos}
            onAdd={(item) => append(item)}
            setDraft={setDraft}
          />
          <FormTableRow bg="green.100" px={4} py={2}>
            <FormTableCell>Total Pago</FormTableCell>
            <FormTableCell>{formatCurrency(total)}</FormTableCell>
          </FormTableRow>
          
        </Stack>
      </Box>
    </FormControl>
  );
};

export default FormControlPagamento;
