import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Heading, Button, Tooltip } from '@chakra-ui/react';
import get from 'lodash/get';

import { useGetAluguel, usePostAluguel, usePutAluguel } from 'actions/alugueis';
import { useGetOptionsItens } from 'actions/options';
import {
  Form,
  FormGroupButton,
  FormFieldset,
  FormControlInputDate,
  FormControlInput,
  FormControlTextarea
} from 'components/form';
import { DisplayText } from 'components/ui';
import { useDocumentTitle } from 'customHooks';
import { prepareInputDateValue } from 'utils/form';

import validationSchema from './validationSchema';
import prepareInputAluguelItens from './prepareInputAluguelItens';
import prepareInputPagamentosCliente from './prepareInputPagamentosCliente';
import prepareOutputData from './prepareOutputData';
import FormControlSelectStatus from './FormControlSelectStatus';
import FormControlSelectCliente from './FormControlSelectCliente';
import FormControlSelectVendedora from './FormControlSelectVendedora';
import FormControlEvento from './FormControlEvento';
import FormControlLocal from './FormControlLocal';
import FormControlPeriodo from './FormControlPeriodo';
import FormControlItensPagamentos from './FormControlItensPagamentos';
import { format } from 'date-fns'

const Formulario = () => {
  const history = useNavigate();
  const { id } = useParams();
  //const toastList = useToastList();
  const { data: aluguel, status: statusAluguel } = useGetAluguel(id);
  const { mutateAsync: criar, status: statusCriar } = usePostAluguel();
  const { mutateAsync: editar, status: statusEditar } = usePutAluguel(id);
  const { status: statusItens } = useGetOptionsItens();
  const [draftItem, setDraftItem] = React.useState(false);
  const [draftPagamento, setDraftPagamento] = React.useState(false);

  const isEditing = Boolean(id);

  useDocumentTitle(isEditing ? `Editar Aluguel ${id}` : 'Nova Reserva');

  const isSubmitting = statusCriar === 'loading' || statusEditar === 'loading';

  const onSubmit = async data => {

    const criarEditar = isEditing ? editar : criar;

    const outputData = prepareOutputData(data, aluguel && aluguel.data);
    const { data: newData } = await criarEditar(outputData);

    if (!isEditing && newData.status === 'FINALIZADO') history('/lavanderia');
    else history(`/aluguel/${newData.id}`);

  };

  // O aluguel precisa estar pronto para o form ser carregado corretamente
  if (isEditing && (statusAluguel === 'loading' || statusItens === 'loading')) {
    return <DisplayText>Carregando...</DisplayText>;
  }

  const defaultValues = {
    aluguel_itens: prepareInputAluguelItens(get(aluguel, 'data.aluguel_itens')),
    pagamentos_cliente: prepareInputPagamentosCliente(
      get(aluguel, 'data.pagamentos_cliente')
    ),
    data_reserva: get(aluguel, 'data.data_reserva') || prepareInputDateValue(format(new Date(), 'yyyy-MM-dd'))
  };

  return (
    <Box p={4}>
      <Heading size="lg">
        {isEditing ? `Aluguel ${id}` : 'Efetua Reserva'}
      </Heading>
      <Form
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        defaultValues={defaultValues}
      >
        <FormFieldset>
          <FormControlInput name="id" type="hidden" />
          <FormControlSelectStatus
            isEditing={isEditing}
            defaultValue={get(aluguel, 'data.status')}
          />
          <FormControlSelectCliente
            isEditing={isEditing}
            defaultValue={get(aluguel, 'data.cliente')}
          />
          <FormControlSelectVendedora
            isEditing={isEditing}
            defaultValue={get(aluguel, 'data.vendedora')}
          />
          <FormControlInputDate
            label="Reserva"
            name="data_reserva"
            width={'165px'}
            defaultValue={prepareInputDateValue(aluguel, 'data.data_reserva')}
          />
          <FormControlPeriodo {...{ aluguel }} />
        </FormFieldset>
        <FormFieldset label="Evento">
          <FormControlEvento {...{ aluguel }} />
          <FormControlLocal {...{ aluguel }} />
          <FormControlInputDate
            label="Data"
            name="data_evento"
            width={'165px'}
            defaultValue={prepareInputDateValue(aluguel, 'data.data_evento')}
          />
        </FormFieldset>
        <FormControlItensPagamentos setDraftItem={setDraftItem} setDraftPagamento={setDraftPagamento} {...{ aluguel }} />
        <FormControlTextarea
        label="Observações"
        name="observacao"
        maxW={500}
        defaultValue={get(aluguel, 'data.observacao')}
        rows="3"
        style={{ height: 'auto' }}
        />
        <FormGroupButton>
          <Box>
            <Tooltip label={draftItem?"Adicione ou limpe o item":(draftPagamento?"Adicione ou limpe o pagamento":"")}>
          <Button
            size="sm"
            colorScheme="blue"
            type="submit"
            loadingText="Salvando"
            isLoading={isSubmitting}
            isDisabled={draftItem || draftPagamento}
            onClick={() => {window.scrollTo(0, 0)}}
          >
            Salvar
          </Button>
          </Tooltip>
          </Box>
        </FormGroupButton>
      </Form>
    </Box>
  );
};

export default Formulario;
