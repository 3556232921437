import React from 'react';
import {
  Link as RouterLink,
  useNavigate
} from 'react-router-dom';
import {
  Stack,
  Flex,
  Input,
  IconButton,
  Select,
} from '@chakra-ui/react';
import get from 'lodash/get';
import { FaSearch, FaPlus } from 'react-icons/fa';
import { stringify } from 'qs';

import { useGetAlugueis } from 'actions/alugueis';
import { useGetOptionsStatusAluguel } from 'actions/options';
import {
  DisplayText,
  Paginator,
  Table,
  TableHead,
  TableBodyZebra,
  TableRow,
  TableHeader,
  TableData,
  StatusAluguel,
  RadioButtonGroup
} from 'components/ui';
import { useSearchParams, useDocumentTitle } from 'customHooks';
import { formatDate } from 'utils/ui';

const initialFiltros = {
  status: '',
  page: 1
};

const TableRowAluguel = ({ value }) => {
  const history = useNavigate();

  const { id, cliente_nome, data_retirada, data_devolucao, status } = value;

  return (
    <TableRow key={id} onClick={() => history(`${id}`)}>
      <TableData>{id}</TableData>
      <TableData>{cliente_nome}</TableData>
      <TableData>{data_retirada ? formatDate(data_retirada) : ''}</TableData>
      <TableData>{data_devolucao ? formatDate(data_devolucao) : ''}</TableData>
      <TableData>
        <StatusAluguel value={status} />
      </TableData>
    </TableRow>
  );
};

const TableAlugueis = ({ filtros, onPaginate }) => {
  const { page } = filtros;
  const { status, data: alugueis, error, hasNext, isFetching } = useGetAlugueis(
    filtros
  );

  if (status === 'loading') return <DisplayText>Carregando...</DisplayText>;

  if (status === 'error')
    return <DisplayText>{get(error, 'response.data.detail')}</DisplayText>;

  const { results } = alugueis.data;

  if (results.length === 0)
    return <DisplayText>Nenhuma informação disponível</DisplayText>;

  return (
    <Stack spacing={4}>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeader>#</TableHeader>
            <TableHeader>Cliente</TableHeader>
            <TableHeader>Data de Retirada</TableHeader>
            <TableHeader>Data de Devolução</TableHeader>
            <TableHeader>Status</TableHeader>
          </TableRow>
        </TableHead>
        <TableBodyZebra>
          {alugueis.data.results.map(aluguel => (
            <TableRowAluguel key={aluguel.id} value={aluguel} />
          ))}
        </TableBodyZebra>
      </Table>
      <Paginator page={page} onPaginate={onPaginate} hasNext={hasNext} isFetching={isFetching}/>
    </Stack>
  );
};

const Filtros = ({ value, onChange, ...rest }) => {
  const { data: statusAluguel } = useGetOptionsStatusAluguel();
  const [termoPesquisa, setTermoPesquisa] = React.useState(value.search);
  
  React.useEffect(() => {
    setTermoPesquisa(value.search || '');
  }, [value]);


  return (
    <Flex {...rest}>
      
      <RadioButtonGroup
        name="status"
        onChange={onch => (onChange({...onch(), page:1}))}
        value={value.status}
        options={(statusAluguel && statusAluguel.data) || []}
        display={{base: 'none', md: 'flex'}}
      />

      <Select
          data-testid="filtro-status"
          size="sm"
          width="150px"
          value={value.status}
          onChange={e => (onChange({status:e.target.value, page:1}))}
          display={{base: 'inline-block', md: 'none'}}
        >
          {((statusAluguel && statusAluguel.data) || []).map(status => (<option key={status.value} value={status.value}>{status.label}</option>))}
        </Select>

      <Flex
        as="form"
        ml="auto"
        noValidate
        onSubmit={e => {
          e.preventDefault();
          onChange({page: 1, search: termoPesquisa });
        }}
      >
        <Input
          size="sm"
          placeholder="Pesquisar"
          roundedRight={0}
          value={termoPesquisa}
          onChange={e => setTermoPesquisa(e.target.value)}
        />
        <IconButton type="submit" size="sm" rounded={0} icon={<FaSearch/>} />
        <IconButton
          as={RouterLink}
          to={`form`}
          size="sm"
          roundedLeft={0}
          icon={<FaPlus/>}
        />
      </Flex>
    </Flex>
  );
};

const Lista = () => {
  const searchParams = useSearchParams(initialFiltros);
  const [filtros, setFiltrosState] = React.useState(searchParams);
  const history = useNavigate();

  useDocumentTitle(`Aluguéis`);

  const setFiltros = React.useCallback(
    newFiltros => {
      const filtros = { ...searchParams, ...newFiltros };
      history(`?${stringify(filtros)}`, { replace: true });
    },
    [setFiltrosState, searchParams]
  );

  React.useEffect(() => {
    setFiltrosState(searchParams);
  }, [searchParams]);

  return (
    <Stack spacing={4} p={4}>
      <Filtros value={filtros} onChange={setFiltros} />
      <TableAlugueis
        filtros={filtros}
        //onPaginate={page => setFiltros(prev => ({ ...prev, page }))}
        onPaginate={page => setFiltros({page})}
      />
    </Stack>
  );
};

export default Lista;
